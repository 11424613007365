//import React from "../../miniclient/in/client/node_modules/react"
//import ReactDOM from "../../miniclient/in/client/node_modules/react-dom"
import moment from "moment"
//import {MC} from '../../miniclient/in/client/src/client/MC.js'
import $ from 'jquery'
import "fullcalendar"
import "fullcalendar/dist/fullcalendar.min.css"

//import './calendar.css'

const ensureArray = value => {
  if (typeof value === 'undefined') {
    return []
  } else if (value instanceof Array) {
    return value
  } else {
    return [value]
  }
}

class Calendar extends React.Component {
  constructor(props) {
    super(props)
    this.state = {events: []}
  }
  componentDidUpdate() {
    const node = ReactDOM.findDOMNode(this)
    $(node).fullCalendar('refetchEvents')
  }
  generateEventsForDay(current) {
    const self = this
    const eventsParams = ensureArray(self.props.data.param['event'])
    const events = ensureArray(eventsParams['@title']).map((title, index) => {
      return {
        validFrom: ensureArray(eventsParams['@validFrom'])[index],
        validTo: ensureArray(eventsParams['@validTo'])[index],
        onMonday: ensureArray(eventsParams['@onMonday'])[index],
        onTuesday: ensureArray(eventsParams['@onTuesday'])[index],
        onWednesday: ensureArray(eventsParams['@onWednesday'])[index],
        onThursday: ensureArray(eventsParams['@onThursday'])[index],
        onFriday: ensureArray(eventsParams['@onFriday'])[index],
        onSaturday: ensureArray(eventsParams['@onSaturday'])[index],
        onSunday: ensureArray(eventsParams['@onSunday'])[index],
        date:  Number(ensureArray(eventsParams['@onDate'])[index]),
        title: title,
        repeatFrequency: ensureArray(eventsParams['@repeatFrequency'])[index],
        time: ensureArray(eventsParams['@time'])[index],
        id: ensureArray(eventsParams['@id'])[index]
      }
    })
    return events.filter(event => {

      const validFrom = moment(event.validFrom, 'YYYY-MM-DD[T]HH:mm:ss')
      const validTo = moment(event.validTo, 'YYYY-MM-DD[T]HH:mm:ss')


      const [hour, minute] = (event.time || "00:00").split(':')
      const eventMoment = moment(current)
      eventMoment.utcOffset(validFrom.utcOffset()).hour(Number(hour)).minute(Number(minute))

      var repeatFrequency = event.repeatFrequency
      if (repeatFrequency === 'no repeat') {
        return moment(event.validFrom, 'YYYY-MM-DDZ').isSame(current, "day")
      }
      if (eventMoment.isBefore(validFrom)) {
        return false
      }
      if (eventMoment.isAfter(validTo)) { 
        return false
      }
      if (repeatFrequency == "daily") {
        return true
      }
      if (repeatFrequency == "monthly") {
        const date = eventMoment.date()
        return date === event.date
      }
      if (repeatFrequency == "weekly") {
        const weekday = eventMoment.weekday()
        const onDays = [event.onSunday, event.onMonday, event.onTuesday, event.onWednesday, event.onThursday, event.onFriday, event.onSaturday]
        return onDays[weekday]
      }
    }).map(function(event) {
      return {title: event.title, start: moment(current), id: event.id}
    })
  }
  generateEvents(start, end) {
    var calendar = this
    var current = moment(start)
    var events = []
    while (current.isSameOrBefore(end)) {
      var eventsForDay = calendar.generateEventsForDay(current)
      events = events.concat(eventsForDay)
      current.add(1, 'day')
    }
    return events
  }
  getDate() {
    if (this.props.data.param['@date']) {
      return moment(this.props.data.param['@date'], 'YYYY-MM-DDZ').format('YYYY-MM-DD')
    } 
    return moment().format('YYYY-MM-DD')
  }
  componentDidMount() {
    const self = this
    const data = self.props.data
    const param = data.param
    const node = ReactDOM.findDOMNode(self)
    
    $(node).fullCalendar({
      defaultDate: self.getDate(),
      editable: false,
      header: false,
      eventLimit: true, // allow "more" link when too many events
      events: (start, end, timezone, callback) => {
        callback(self.generateEvents(start, end))
      },
      eventClick: (calEvent, jsEvent, view) => {
        param['@selectedEventId'] = calEvent.id
        data.onSubmit(data)
      },
    })
  }
  render() {
    return <div/>
  }
}

export {Calendar}

MC.registerReactRomponent('calendar', Calendar)
